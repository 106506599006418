export enum FundedTimesheetStatusEnum {
  DRAFT = 'DA_LAV',
  IN_PROGRESS = 'IN_LAV',
  WORKED = 'LAV'
}

export enum FundedTimesheetStatusIdEnum {
  DRAFT = 1,
  IN_PROGRESS = 2,
  WORKED = 3
}
