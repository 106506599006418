import { Pipe, PipeTransform } from '@angular/core';
import { IPermission } from '@shared/models/interfaces/permission.interface';

@Pipe({
  name: 'permissionSort',
  standalone: true
})
export class PermissionSortPipe implements PipeTransform {

  transform(value: IPermission[]): IPermission[] {
    return [...value].sort(({ name: previousName }, { name: currentName }) => previousName.localeCompare(currentName));
  }

}
