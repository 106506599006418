import { Pipe, PipeTransform } from '@angular/core';
import { TimesheetUtils } from '@shared/utils/timesheet.utils';

@Pipe({
  name: 'hours',
  standalone: true
})
export class HoursPipe implements PipeTransform {

  transform(value: number, showMinutes: boolean = true): string {
    return !!value ? TimesheetUtils.parseHours(value, showMinutes) : '-';
  }

}
