import { Pipe, PipeTransform } from '@angular/core';
import { TimesheetStatusEnum } from '@shared/enums/timesheet-status.enum';
import { translateTimesheetStatus } from '@shared/functions/translate-timesheet-status';

@Pipe({
  name: 'timesheetStatus',
  standalone: true
})
export class TimesheetStatusPipe implements PipeTransform {

  transform(value: TimesheetStatusEnum): string {
    return translateTimesheetStatus(value);
  }

}
