import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ITableColumn } from '@nesea/ngx-ui-kit/shared';
import { IOvertimeTimesheet, IWorkingDayTimesheet } from '@shared/models/interfaces/timesheet.interface';

type OvertimeTimesheet = { id: number, name: string, customer: string, type: string, hours: number };

@Component({
  selector: 'nsf-timesheet-summary-overtimes',
  templateUrl: './timesheet-summary-overtimes.component.html',
  styleUrl: './timesheet-summary-overtimes.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimesheetSummaryOvertimesComponent implements OnInit {

  @Input({ required: true }) overtimeDays: IWorkingDayTimesheet<IOvertimeTimesheet>[];

  columns: ITableColumn<OvertimeTimesheet>[] = [];
  items: OvertimeTimesheet[] = [];

  ngOnInit(): void {
    this.columns = [
      {
        id: 'name',
        label: 'LABEL.ORDER.TEXT',
        property: 'name'
      },
      {
        id: 'customer',
        label: 'LABEL.CUSTOMER.TEXT',
        property: 'customer'
      },
      {
        id: 'type',
        label: 'LABEL.TYPE.TEXT',
        property: 'type'
      },
      {
        id: 'hours',
        label: 'LABEL.TOTAL_HOURS.TEXT',
        property: 'hours',
        getValue: (item): string => {
          const hours: number = Math.floor(item.hours);
          const minutes: number = Math.round((item.hours - hours) * 60);
          return `${hours}h : ${minutes}m`;
        }
      }
    ];

    this.items = this.overtimeDays.reduce((output, orders) => {
      orders.commesseOreTimesheet.forEach(order => {
        const id: number = order.id;
        const type: string = order.tipo;
        const found: OvertimeTimesheet = output.find(ord => ord.id === id && ord.type === type);
        !!found ? found.hours += order.oreLavorate : output.push({ id, name: order.name, customer: order.clientName, type, hours: order.oreLavorate });
      });
      return output;
    }, [] as OvertimeTimesheet[]);
    this.items.sort((a, b) => a.name.localeCompare(b.name));
  }

}
