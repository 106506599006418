<ng-container *transloco="let transloco">

  <div class="d-flex flex-column align-items-end mb-2">
    @if(!!timesheetStatus()) {
      <span class="fw-bold">{{ transloco('TIMESHEET.STATUS.TEXT') }}&#58; {{ timesheetStatus().nome }}</span>
    }
  </div>

  @if(!isTimesheetError()) {
    <div class="d-grid d-lg-flex gap-2 justify-content-lg-between mb-5">
      <div class="d-grid d-lg-flex gap-2">
        <nsa-button
          nsaId="nsf-month-summary-button"
          size="sm"
          color="dark"
          icon="calendar-check"
          iconColor="secondary"
          [title]="transloco('TIMESHEET.ACTION.MONTH_SUMMARY')"
          [fullWidth]="true"
          (click)="onMonthSummaryClick()">
          {{ transloco('TIMESHEET.ACTION.MONTH_SUMMARY') }}
        </nsa-button>

        @if(!management() && !!isTimesheetDraft()) {
          <nsa-button
            nsaId="nsf-remove-orders-button"
            size="sm"
            color="dark"
            icon="calendar-minus"
            iconColor="danger"
            [title]="transloco('TIMESHEET.ACTION.REMOVE_ORDERS')"
            [fullWidth]="true"
            (click)="onRemoveOrdersClick()">
            {{ transloco('TIMESHEET.ACTION.REMOVE_ORDERS') }}
          </nsa-button>

          <nsa-button
            nsaId="nsf-add-orders-button"
            size="sm"
            color="dark"
            icon="calendar-plus"
            iconColor="success"
            [title]="transloco('TIMESHEET.ACTION.ADD_ORDERS')"
            [fullWidth]="true"
            (click)="onAddOrdersClick()">
            {{ transloco('TIMESHEET.ACTION.ADD_ORDERS') }}
          </nsa-button>
        }
      </div>

      <div class="d-grid d-lg-flex justify-content-lg-end">
        @if(!management() && !!timesheetStatus() && !!isTimesheetDraft()) {
          <nsa-button
            nsaId="nsf-month-send-timesheet-button"
            size="sm"
            color="dark"
            icon="send-check"
            iconColor="success"
            [title]="transloco('TIMESHEET.ACTION.SEND')"
            [fullWidth]="true"
            (click)="onSendTimesheetClick()">
            {{ transloco('TIMESHEET.ACTION.SEND') }}
          </nsa-button>
        } @else if(!!management() && !!timesheetStatus() && !!isTimesheetSent()) {
          <div class="d-grid d-lg-flex gap-2 justify-content-lg-end">
            @if(!!hasRejectPermission()) {
              <nsa-button
                nsaId="nsf-month-reject-timesheet-button"
                size="sm"
                color="dark"
                icon="x-lg"
                iconColor="danger"
                [title]="transloco('TIMESHEET.ACTION.REJECT')"
                [fullWidth]="true"
                (click)="onRejectTimesheetClick()">
                {{ transloco('TIMESHEET.ACTION.REJECT') }}
              </nsa-button>
            }

            @if(!!hasApprovePermission()) {
              <nsa-button
                nsaId="nsf-month-approve-timesheet-button"
                size="sm"
                color="dark"
                icon="check-lg"
                iconColor="success"
                [title]="transloco('TIMESHEET.ACTION.APPROVE')"
                [fullWidth]="true"
                (click)="onApproveTimesheetClick()">
                {{ transloco('TIMESHEET.ACTION.APPROVE') }}
              </nsa-button>
            }
          </div>
        }
      </div>
    </div>
  }

  @if(!isTimesheetError()) {
    <div class="d-flex align-items-center justify-content-center gap-2 mb-2">
      @if(!management()) {
        <nsa-button-icon
          nsaId="nsf-previous-month-button"
          size="sm"
          color="secondary"
          icon="chevron-left"
          [outlined]="true"
          [title]="transloco('TIMESHEET.ACTION.PREVIOUS_MONTH')"
          (click)="onPreviousMonthClick()">
          {{ transloco('TIMESHEET.ACTION.PREVIOUS_MONTH') }}
        </nsa-button-icon>

        <nsa-button
          nsaId="nsf-current-month-button"
          size="sm"
          color="secondary"
          [outlined]="true"
          [title]="currentMonthTitle()"
          (click)="onSearchClick()">
          {{ currentMonthTitle() }}
        </nsa-button>

        <nsa-button-icon
          nsaId="nsf-next-month-button"
          size="sm"
          color="secondary"
          icon="chevron-right"
          [outlined]="true"
          [title]="transloco('TIMESHEET.ACTION.NEXT_MONTH')"
          [disabled]="nextMonthDisabled()"
          (click)="onNextMonthClick()">
          {{ transloco('TIMESHEET.ACTION.NEXT_MONTH') }}
        </nsa-button-icon>
      } @else {
        <div class="d-flex flex-column justify-content-center align-items-center gap-2">
          <h3>{{ currentMonthTitle() | titlecase }}</h3>
          <h5 class="text-secondary">{{ userData()?.name | titlecase }} {{ userData()?.lastname | titlecase }}</h5>
        </div>
      }
    </div>
  }

  <div class="d-flex justify-content-end mb-2">
    <nsa-checkbox
      nsaId="nsf-workingweek-id"
      nsaName="nsf-workingweek-name"
      label="TIMESHEET.ACTION.SHOW_HOLIDAYS"
      type="switch"
      [reverse]="true"
      [ngModel]="showHolidays()"
      (valueChange)="onWorkingweekClick()">
    </nsa-checkbox>
  </div>

  @if(!!isTimesheetError() && !isSpinnerLoading()) {
    <div class="row">
      <div class="col-12 p-2">
        <nsa-alert
          color="danger"
          className="text-center">
          {{ transloco('TIMESHEET.ALERT.ERROR') }}
        </nsa-alert>
      </div>
    </div>
  }

  <full-calendar
    #fullCalendar
    id="timesheet-calendar"
    [options]="fullCalendarOptions()">
  </full-calendar>
</ng-container>


