export enum TimesheetStatusEnum {
  DRAFT = 'BOZ',
  SENT = 'INV',
  APPROVED = 'APP',
  REJECTED = 'RIF'
}

export enum TimesheetStatusIdEnum {
  DRAFT = 1,
  SENT = 2,
  APPROVED = 3,
  REJECTED = 4
}
