import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, shareReplay, switchMap, tap } from 'rxjs';
import { IConfig } from '@core/models/interfaces/config.interface';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { MenuService } from '@core/services/menu.service';
import { DailyPhrasesService } from '@core/services/daily-phrases.service';
import { IMenuConfig } from '@core/models/interfaces/menu-config.interface';
import { IDailyPhrasesConfig } from '@core/models/interfaces/daily-phrases-config.interface';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private _httpClient: HttpClient = inject(HttpClient);
  private _menuService: MenuService = inject(MenuService);
  private _dailyPhrasesService: DailyPhrasesService = inject(DailyPhrasesService);

  private _configLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _config: IConfig;

  get configLoaded$(): Observable<boolean> {
    return this._configLoaded$.asObservable();
  }

  get config(): IConfig {
    return this._config;
  }

  get configUrl(): string {
    return environment.configUrl;
  }

  get env(): string {
    return this._config.environment !== 'production' ? this.config.environment : undefined;
  }

  load(): Observable<IConfig> {
    return this._httpClient.get<IConfig>(this.configUrl).pipe(
      shareReplay({ refCount: true, bufferSize: 1 }),
      tap(remoteConfig => this._config = { ...remoteConfig }),
      switchMap(remoteConfig => this._menuService.retrieveConfig(remoteConfig.menu, 'menu-config.json', []).pipe(
        map(() => remoteConfig)
      )),
      switchMap(remoteConfig => this._dailyPhrasesService.retrieveConfig(remoteConfig.dailyPhrases, 'daily-phrases-config.json', {}).pipe(
        map(() => remoteConfig)
      )),
      tap(() => this._configLoaded$.next(true)),
    );
  }

}
