import { Pipe, PipeTransform } from '@angular/core';
import { IOrder } from '@shared/models/interfaces/order.interface';

@Pipe({
  name: 'order',
  standalone: true
})
// TODO: Rename in sort
export class OrderPipe implements PipeTransform {

  transform(value: Partial<IOrder>[]): Partial<IOrder>[] {
    return [...value].sort(({ name: previousName }, { name: currentName }) => previousName.localeCompare(currentName));
  }

}
