<ng-container *transloco="let transloco">

  <div class="d-flex justify-content-lg-between flex-column gap-2 mb-2">
    @if(!!fundedTimesheet()) {
      <span class="fw-bold">{{ transloco('FUNDED_TIMESHEETS_MANAGEMENT.TOTAL_HOURS.TEXT') }}&#58; {{ fundedTimesheet().totaleOre | hours: false }}</span>
      <span class="fw-bold">{{ transloco('FUNDED_TIMESHEETS_MANAGEMENT.BUSY_HOURS.TEXT') }}&#58; {{ fundedTimesheet().totaleOreOccupate | hours: false }}</span>
      <span class="fw-bold">{{ transloco('FUNDED_TIMESHEETS_MANAGEMENT.FREE_HOURS.TEXT') }}&#58; {{ fundedTimesheet().totaleOreLibere | hours: false }}</span>
    }

    <div class="d-flex flex-column align-items-end">
      @if(!!fundedTimesheetStatus()) {
        <span class="fw-bold">{{ transloco('TIMESHEET.STATUS.TEXT') }}&#58; {{ fundedTimesheetStatus().nome }}</span>
      }
    </div>
  </div>

  @if(!isTimesheetError()) {
    <div class="d-grid d-lg-flex gap-2 justify-content-lg-between mb-5">
      <div class="d-grid d-lg-flex gap-2">
        @if(!isTimesheetWorked()) {
          <nsa-button
            nsaId="nsf-remove-orders-button"
            size="sm"
            color="dark"
            icon="calendar-minus"
            iconColor="danger"
            [title]="transloco('TIMESHEET.ACTION.REMOVE_ORDERS')"
            [fullWidth]="true"
            (click)="onRemoveOrdersClick()">
            {{ transloco('TIMESHEET.ACTION.REMOVE_ORDERS') }}
          </nsa-button>

          <nsa-button
            nsaId="nsf-add-orders-button"
            size="sm"
            color="dark"
            icon="calendar-plus"
            iconColor="success"
            [title]="transloco('TIMESHEET.ACTION.ADD_ORDERS')"
            [fullWidth]="true"
            (click)="onAddOrdersClick()">
            {{ transloco('TIMESHEET.ACTION.ADD_ORDERS') }}
          </nsa-button>
        }
      </div>

      <div class="d-grid d-lg-flex justify-content-lg-end">
        <div class="d-grid d-lg-flex gap-2 justify-content-lg-end">
          @if(!!fundedTimesheetStatus()) {
            @if(!!isTimesheetDraft() || !!isTimesheetWorked()) {
              <nsa-button
                nsaId="nsf-save-timesheet-button"
                size="sm"
                color="dark"
                icon="floppy"
                iconColor="success"
                [title]="transloco('TIMESHEET.ACTION.IN_PROGRESS')"
                [fullWidth]="true"
                (click)="onSaveTimesheetClick()">
                {{ transloco('TIMESHEET.ACTION.IN_PROGRESS') }}
              </nsa-button>
            }

            @if(!!isTimesheetDraft() || !!isTimesheetInProgress()) {
              <nsa-button
                nsaId="nsf-close-timesheet-button"
                size="sm"
                color="dark"
                icon="check-lg"
                iconColor="primary"
                [title]="transloco('TIMESHEET.ACTION.CLOSE')"
                [fullWidth]="true"
                (click)="onCloseTimesheetClick()">
                {{ transloco('TIMESHEET.ACTION.CLOSE') }}
              </nsa-button>
            }
          }
        </div>
      </div>
    </div>
  }

  @if(!isTimesheetError()) {
    <div class="d-flex align-items-center justify-content-center gap-2 mb-2">
      <div class="d-flex flex-column justify-content-center align-items-center gap-2">
        <h3>{{ currentMonthTitle() | titlecase }}</h3>
        <h5 class="text-secondary">{{ userData()?.name | titlecase }} {{ userData()?.lastname | titlecase }}</h5>
      </div>
    </div>
  }

  <div class="d-flex justify-content-end mb-2">
    <nsa-checkbox
      nsaId="nsf-workingweek-id"
      nsaName="nsf-workingweek-name"
      label="TIMESHEET.ACTION.SHOW_HOLIDAYS"
      type="switch"
      [reverse]="true"
      [ngModel]="showHolidays()"
      (valueChange)="onWorkingweekClick()">
    </nsa-checkbox>
  </div>

  @if(!!isTimesheetError() && !isSpinnerLoading()) {
    <div class="row">
      <div class="col-12 p-2">
        <nsa-alert
          color="danger"
          className="text-center">
          {{ transloco('TIMESHEET.ALERT.ERROR') }}
        </nsa-alert>
      </div>
    </div>
  }

  <full-calendar
    #fullCalendar
    id="timesheet-calendar"
    [options]="fullCalendarOptions()">
  </full-calendar>

</ng-container>
