import { inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { IResponse, IResultMessage } from '@core/models/interfaces/response.interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IFundedTimesheet, IFundedWorkingDayTimesheet } from '@shared/models/interfaces/funded-timesheet.interface';
import { ITimesheetDayRequest, ITimesheetSaveRequest } from '@shared/models/interfaces/timesheet-save-request.interface';
import { ISearchResponse } from '@shared/models/interfaces/search-response.interface';
import { IFundedTimesheetsMgmtSearchFilters } from '@features/funded-timesheets-management/models/interfaces/funded-timesheets-mgmt-search-filters.interface';
import { IFundedTimesheetSearchData } from '@features/funded-timesheets-management/models/interfaces/funded-timesheet-search-data.interface';
import { FundedTimesheetStatusEnum } from '@shared/enums/funded-timesheet-status.enum';

@Injectable({
  providedIn: 'root'
})
export class FundedTimesheetService {

  private _httpClient: HttpClient = inject(HttpClient);

  getTimesheets(filters: IFundedTimesheetsMgmtSearchFilters): Observable<ISearchResponse<IFundedTimesheetSearchData>> {
    return this._httpClient.post<IResponse<ISearchResponse<IFundedTimesheetSearchData>>>(`/timesheetFinanziati/ricerca`, { ...filters }).pipe(
      map(({ data }) => data)
    );
  }

  getTimesheet(userId: number, month?: number, year?: number): Observable<IFundedTimesheet> {
    let params: HttpParams = new HttpParams()
      .set('idUtente', userId);
    if(!!month) params = params.set('mese', month);
    if(!!year) params = params.set('anno', year);

    return this._httpClient.get<IResponse<IFundedTimesheet>>(`/timesheetFinanziati/getTimesheet`, { params }).pipe(
      map(({ data }) => data)
    );
  }

  saveTimesheet(userId: number, month: number, year: number, workingDays: IFundedWorkingDayTimesheet[]): Observable<IResultMessage> {
    const request: ITimesheetSaveRequest = {
      idUtente: userId,
      mese: month,
      anno: year,
      giornoTimesheet: workingDays.map<ITimesheetDayRequest>(({ giorno, commessa: order, attivita: activity }) => ({
        giorno,
        commesseOre: !!order ?
          [{ idCommessa: order.id, oreLavorate: order.oreLavorate }] :
          (!!activity ?
            [{ idAttivita: activity.id, oreLavorate: activity.oreLavorate }] :
            []
          )
      }))
    };

    return this._httpClient.post<IResponse<IResultMessage>>(`/timesheetFinanziati/save`, request).pipe(
      map(({ data }) => data)
    );
  }

  partialWorkTimesheet(timesheetId: number, note: string): Observable<IResultMessage> {
    const request = {
      idTimesheet: timesheetId,
      statoCode: FundedTimesheetStatusEnum.IN_PROGRESS,
      note
    };

    return this._httpClient.post<IResponse<IResultMessage>>(`/timesheetFinanziati/aggiornaStato`, request).pipe(
      map(({ data }) => data)
    );
  }

  closeTimesheet(timesheetId: number, note: string): Observable<IResultMessage> {
    const request = {
      idTimesheet: timesheetId,
      statoCode: FundedTimesheetStatusEnum.WORKED,
      note
    };

    return this._httpClient.post<IResponse<IResultMessage>>(`/timesheetFinanziati/aggiornaStato`, request).pipe(
      map(({ data }) => data)
    );
  }

  exportTimesheet(month: number, year: number): Observable<boolean> {
    let params: HttpParams = new HttpParams()
      .set('mese', month)
      .set('anno', year);
    const request = {};

    return this._httpClient.post<IResponse<boolean>>(`/timesheetFinanziati/generateExcel`, request, { params }).pipe(
      map(({ data }) => data)
    );
  }

}
