<ng-container *transloco="let transloco">
  <nsa-modal>
    <ng-container modal-body>
      <form [formGroup]="form">
        <div class="d-flex justify-content-end pt-2">
          <nsa-checkbox
            nsaId="nsf-order-multiple-day-id"
            nsaName="nsf-order-multiple-day-name"
            label="LABEL.MULTIPLE_DAY.TEXT"
            formControlName="showEndDate"
            [reverse]="true"
            (valueChange)="onShowEndDateChange($event)">
          </nsa-checkbox>
        </div>

        <div class="row">
          <div class="col-12 col-lg-6 py-2">
            <nsa-datepicker
              nsaId="nsf-order-start-id"
              nsaName="nsf-order-start-name"
              label="LABEL.START_DATE.TEXT"
              placeholder="LABEL.START_DATE.PLACEHOLDER"
              [clearable]="true"
              [config]="startDateConfig"
              formControlName="startDate"
              (valueChange)="onStartDateChange($event)">
            </nsa-datepicker>
          </div>

          <div class="col-12 col-lg-6 py-2">
            <nsa-datepicker
              nsaId="nsf-order-end-id"
              nsaName="nsf-order-end-name"
              label="LABEL.END_DATE.TEXT"
              placeholder="LABEL.END_DATE.PLACEHOLDER"
              [clearable]="true"
              [config]="endDateConfig"
              formControlName="endDate"
              (valueChange)="onEndDateChange($event)">
            </nsa-datepicker>
          </div>
        </div>
        @if(!isSpinnerLoading() && hasStartDate && hasEndDate) {
          @if(!userOrders?.length) {
            <div class="row">
              <div class="col-12 py-2">
                <nsa-alert
                  color="danger"
                  className="text-center">
                  {{ transloco('TIMESHEET.ALERT.NO_ORDER') }}
                </nsa-alert>
              </div>
            </div>
          } @else {
            <div class="row">
              <div class="col-12 col-lg-6 py-2">
                <nsa-select
                  nsaId="nsf-order-id"
                  nsaName="nsf-order-name"
                  [clearable]="true"
                  label="LABEL.ORDER.TEXT"
                  placeholder="LABEL.ORDER.PLACEHOLDER"
                  [options]="userOrders"
                  [compareFn]="compareOrderOptionsFn"
                  bindLabel="name"
                  bindValue="id"
                  [searchable]="true"
                  formControlName="orderName"
                  (valueChange)="onOrderChange($event)">
                </nsa-select>
              </div>

              <div class="col-12 col-lg-6 py-2">
                <nsa-input
                  nsaId="nsf-order-customer-id"
                  nsaName="nsf-order-customer-name"
                  [clearable]="false"
                  label="LABEL.CUSTOMER.TEXT"
                  formControlName="customer">
                </nsa-input>
              </div>
            </div>
          }
        }
      </form>
    </ng-container>

    <ng-container modal-footer>
      <div class="d-grid gap-2 d-md-flex justify-content-md-center w-100">
        <nsa-button
          nsaId="nsf-order-abort"
          [title]="transloco('ACTION.ABORT')"
          type="button"
          icon="x-lg"
          color="dark"
          iconColor="secondary"
          [fullWidth]="true"
          (click)="onAbort()">
          {{ transloco("ACTION.ABORT") }}
        </nsa-button>

        <nsa-button
          nsaId="nsf-order-confirm"
          [title]="transloco('ACTION.CONFIRM')"
          icon="check-lg"
          color="dark"
          iconColor="success"
          [fullWidth]="true"
          (click)="onConfirm()">
          {{ transloco("ACTION.CONFIRM") }}
        </nsa-button>
      </div>
    </ng-container>
  </nsa-modal>
</ng-container>
