import { Pipe, PipeTransform } from '@angular/core';
import { translate } from '@jsverse/transloco';
import { EnumUtils } from '@shared/utils/enum.utils';
import { FundedActivityTypeEnum } from '@shared/enums/funded-activity-type.enum';

@Pipe({
  name: 'fundedActivityType',
  standalone: true
})
export class FundedActivityTypePipe implements PipeTransform {

  transform(value: FundedActivityTypeEnum): string {
    if(!value) return '';
    const key: string = EnumUtils.getKey(FundedActivityTypeEnum, value);
    return !!value ? translate(`LABEL.${key}.TEXT`) : '';
  }

}
