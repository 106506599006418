import { inject, Injectable } from '@angular/core';
import { ConfigService } from '@core/services/config.service';
import { IFormConfig } from '@core/models/interfaces/config.interface';

@Injectable({
  providedIn: 'root'
})
export class FormConfigService {

  private _configService: ConfigService = inject(ConfigService);

  get config(): IFormConfig {
    return this._configService.config.form;
  }

}
