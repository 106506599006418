import * as i0 from '@angular/core';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
class ToastService {
  constructor() {
    this._id = 0;
    this._messages$ = new BehaviorSubject(null);
  }
  get messages$() {
    return this._messages$.asObservable();
  }
  showInfo(data) {
    this._notify(data, 'info');
  }
  showSuccess(data) {
    this._notify(data, 'success');
  }
  showWarning(data) {
    this._notify(data, 'warning');
  }
  showDanger(data) {
    this._notify(data, 'danger');
  }
  _notify({
    title,
    message,
    translateParams
  }, severity) {
    const id = this._id++;
    this._messages$.next({
      id,
      title,
      message,
      translateParams,
      severity
    });
  }
  static {
    this.ɵfac = function ToastService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ToastService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ToastService,
      factory: ToastService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ToastService, [{
    type: Injectable
  }], () => [], null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ToastService };
