<ng-container *transloco="let transloco">
  <!-- TODO: -->
  <nsa-modal>
    <ng-container modal-body>
      <div class="row">
        <div class="col-12 py-2">
          <p class="text-center">{{ transloco(!!data.message ? data.message : 'MODAL.CONFIRM.MESSAGE', data.translateParams) }}</p>
        </div>
      </div>
      <form [formGroup]="form">
        <div class="row">
          <div class="col-12 py-2">
            <nsa-checkbox
              nsaId="nsf-attachments-id"
              nsaName="nsf-attachments-name"
              label="LABEL.ATTACHMENTS.TEXT"
              [reverse]="true"
              formControlName="attachments"
              (valueChange)="onAttachmentsChange()">
            </nsa-checkbox>
          </div>

          @if(showAttachments) {
            <div class="col-12">
              <nsa-alert
                color="info"
                className="text-center">
                <span>{{ transloco('ALERT.INFO.FILE', { acceptedExtensions: acceptedExtensions, maxSize: maxSize }) }}</span>
              </nsa-alert>
            </div>

            <div class="col-12 py-2">
              @if(showAlert) {
                <nsa-alert
                  color="warning"
                  className="text-center">
                  <span>{{ transloco('TIMESHEET.ALERT.UNIQUE_DOCUMENT') }}</span>
                </nsa-alert>
              }
            </div>

            <div class="col-12 py-2">
              <nsa-input-file
                nsaId="nsf-input-file-timesheet"
                nsaName="nsf-input-file-timesheet-name"
                size="md"
                label="LABEL.DOCUMENTS.TEXT"
                placeholder="LABEL.DOCUMENTS.PLACEHOLDER"
                [multiple]="true"
                [acceptedFyleType]="acceptedFileTypes"
                formControlName="files"
                (valueChange)="onFileChange($event)">
              </nsa-input-file>
            </div>

            <div class="col-12 py-2">
              <nsa-table
                nsaId="nsf-timesheet-attachments-table"
                type="collapsed"
                itemPropertyId="nomeFile"
                [columns]="columns"
                [items]="items"
                [actions]="actions"
                [serverSide]="false">
              </nsa-table>
            </div>
          }
        </div>
      </form>
    </ng-container>

    <ng-container modal-footer>
      <div class="d-grid gap-2 d-md-flex justify-content-md-center w-100">
        <nsa-button
          nsaId="nsf-cancel-button"
          type='button'
          icon="x-lg"
          color="dark"
          iconColor="danger"
          [fullWidth]="true"
          (click)="onAbort()">
          {{ transloco('ACTION.ABORT') }}
        </nsa-button>

        <nsa-button
          nsaId="nsf-confirm-button"
          type='submit'
          icon="check-lg"
          color="dark"
          iconColor="success"
          [fullWidth]="true"
          (click)="onConfirm()">
          {{ transloco('ACTION.CONFIRM') }}
        </nsa-button>
      </div>
    </ng-container>
  </nsa-modal>
</ng-container>

