import { BehaviorSubject, Subject, map, filter, Subscription, take, tap } from 'rxjs';
import * as i0 from '@angular/core';
import { Injectable, Directive, Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { SharedUtils, SharedModule } from '@nesea/ngx-ui-kit/shared';
import * as i2 from '@angular/common';
import * as i3 from '@jsverse/transloco';
const _c0 = [[["", "modal-sidebar", ""]], [["", "modal-body", ""]], [["", "modal-footer", ""]]];
const _c1 = ["[modal-sidebar]", "[modal-body]", "[modal-footer]"];
const _c2 = a0 => ({
  "flex-row": a0
});
function ModalComponent_Conditional_2_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function ModalComponent_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵprojection(1);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(2, ModalComponent_Conditional_2_ng_container_2_Template, 1, 0, "ng-container", 3);
  }
  if (rf & 2) {
    i0.ɵɵnextContext();
    const contentRef_r1 = i0.ɵɵreference(5);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngTemplateOutlet", contentRef_r1);
  }
}
function ModalComponent_Conditional_3_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function ModalComponent_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, ModalComponent_Conditional_3_ng_container_0_Template, 1, 0, "ng-container", 3);
  }
  if (rf & 2) {
    i0.ɵɵnextContext();
    const contentRef_r1 = i0.ɵɵreference(5);
    i0.ɵɵproperty("ngTemplateOutlet", contentRef_r1);
  }
}
function ModalComponent_ng_template_4_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 4)(1, "div", 5)(2, "h5", 6);
    i0.ɵɵtext(3);
    i0.ɵɵpipe(4, "transloco");
    i0.ɵɵpipe(5, "uppercase");
    i0.ɵɵpipe(6, "transloco");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(7, "button", 7);
    i0.ɵɵpipe(8, "transloco");
    i0.ɵɵlistener("click", function ModalComponent_ng_template_4_Template_button_click_7_listener() {
      i0.ɵɵrestoreView(_r2);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.onClose());
    });
    i0.ɵɵelementEnd()();
    i0.ɵɵelementStart(9, "div", 8);
    i0.ɵɵprojection(10, 1);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(11, "div", 9);
    i0.ɵɵprojection(12, 2);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("id", ctx_r2.getModalId(ctx_r2.id) + "-title");
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", (ctx_r2.config == null ? null : ctx_r2.config.titleUppercase) ? i0.ɵɵpipeBind1(5, 6, i0.ɵɵpipeBind2(4, 3, ctx_r2.config == null ? null : ctx_r2.config.title, ctx_r2.config == null ? null : ctx_r2.config.translateParams)) : i0.ɵɵpipeBind2(6, 8, ctx_r2.config == null ? null : ctx_r2.config.title, ctx_r2.config == null ? null : ctx_r2.config.translateParams), " ");
    i0.ɵɵadvance(4);
    i0.ɵɵattribute("aria-label", i0.ɵɵpipeBind1(8, 11, "NSA.ACTION.CLOSE"));
  }
}
class ModalViewerService {
  constructor() {
    this._id = 0;
    this._queue = new BehaviorSubject({
      id: this._id,
      component: undefined
    });
    this._output = new Subject();
  }
  getModalData$() {
    return this._queue.asObservable().pipe(map(({
      id,
      component,
      config
    }) => ({
      id,
      component: component,
      config: config
    })));
  }
  open(component, config) {
    const id = this._id++;
    this._queue.next({
      id,
      component,
      config: {
        ...config,
        translateParams: config.translateParams || {},
        className: config.className || ''
      }
    });
    return this._output.asObservable().pipe(filter(({
      id: modalId
    }) => modalId === id), map(({
      output
    }) => output));
  }
  close(id, output) {
    this._output.next({
      id,
      output
    });
    this._queue.next({
      id,
      component: undefined
    });
  }
  static {
    this.ɵfac = function ModalViewerService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ModalViewerService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ModalViewerService,
      factory: ModalViewerService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ModalViewerService, [{
    type: Injectable
  }], null, null);
})();
class AbstractModal {
  constructor(modalViewerService) {
    this.modalViewerService = modalViewerService;
    this._sub = new Subscription();
  }
  ngOnInit() {
    this._sub = this.modalViewerService.getModalData$().pipe(take(1), tap(({
      id,
      config
    }) => {
      this.id = id;
      this.data = config.data;
      this.onInit();
    })).subscribe();
  }
  ngOnDestroy() {
    this._sub?.unsubscribe();
    this.onDestroy();
  }
  close(output) {
    this.modalViewerService.close(this.id, output);
  }
  static {
    this.ɵfac = function AbstractModal_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AbstractModal)(i0.ɵɵdirectiveInject(ModalViewerService));
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: AbstractModal
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AbstractModal, [{
    type: Directive
  }], () => [{
    type: ModalViewerService
  }], null);
})();
class ModalComponent {
  constructor(_modalViewerService) {
    this._modalViewerService = _modalViewerService;
    this._sub = new Subscription();
  }
  ngOnInit() {
    this._sub = this._modalViewerService.getModalData$().pipe(take(1), tap(({
      id,
      config
    }) => {
      this.id = id;
      this.config = config;
    })).subscribe();
  }
  ngOnDestroy() {
    this._sub.unsubscribe();
  }
  getModalId(id) {
    return SharedUtils.generateId('modal', id);
  }
  onClose() {
    this._modalViewerService.close(this.id);
  }
  static {
    this.ɵfac = function ModalComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ModalComponent)(i0.ɵɵdirectiveInject(ModalViewerService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: ModalComponent,
      selectors: [["nsa-modal"]],
      ngContentSelectors: _c1,
      decls: 6,
      vars: 10,
      consts: [["contentRef", ""], [3, "ngClass"], [1, "modal-sidebar"], [4, "ngTemplateOutlet"], [1, "nsa-modal-content-container"], [1, "modal-header"], [1, "modal-title", "text-secondary", "text-center", "fw-bold", "w-100", 3, "id"], ["type", "button", "data-bs-dismiss", "modal", 1, "btn-close", 3, "click"], [1, "modal-body"], [1, "modal-footer", "d-flex", "justify-content-center"]],
      template: function ModalComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵelementStart(0, "div")(1, "div", 1);
          i0.ɵɵtemplate(2, ModalComponent_Conditional_2_Template, 3, 1)(3, ModalComponent_Conditional_3_Template, 1, 1, "ng-container")(4, ModalComponent_ng_template_4_Template, 13, 13, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵclassMapInterpolate1("modal-dialog modal-", ctx.config == null ? null : ctx.config.size, " modal-dialog-centered");
          i0.ɵɵadvance();
          i0.ɵɵclassMapInterpolate1("modal-content ", ctx.config == null ? null : ctx.config.className, "");
          i0.ɵɵproperty("ngClass", i0.ɵɵpureFunction1(8, _c2, !!ctx.config.sidebar && (ctx.config.size === "lg" || ctx.config.size === "xl")));
          i0.ɵɵadvance();
          i0.ɵɵconditional(!!ctx.config.sidebar && (ctx.config.size === "lg" || ctx.config.size === "xl") ? 2 : 3);
        }
      },
      dependencies: [i2.NgClass, i2.NgTemplateOutlet, i2.UpperCasePipe, i3.TranslocoPipe],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ModalComponent, [{
    type: Component,
    args: [{
      selector: 'nsa-modal',
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<div class=\"modal-dialog modal-{{config?.size}} modal-dialog-centered\">\r\n  <div\r\n    class=\"modal-content {{config?.className}}\"\r\n    [ngClass]=\"{ 'flex-row': !!config.sidebar && (config.size === 'lg' || config.size === 'xl') }\">\r\n\r\n    @if(!!config.sidebar && (config.size === 'lg' || config.size === 'xl')) {\r\n      <div class=\"modal-sidebar\">\r\n        <ng-content select=\"[modal-sidebar]\"></ng-content>\r\n      </div>\r\n\r\n      <ng-container *ngTemplateOutlet=\"contentRef\"></ng-container>\r\n    } @else {\r\n      <ng-container *ngTemplateOutlet=\"contentRef\"></ng-container>\r\n    }\r\n\r\n    <ng-template #contentRef>\r\n      <div class=\"nsa-modal-content-container\">\r\n        <div class=\"modal-header\">\r\n          <h5\r\n            [id]=\"getModalId(id) + '-title'\"\r\n            class=\"modal-title text-secondary text-center fw-bold w-100\">\r\n            {{ config?.titleUppercase ? (config?.title | transloco: config?.translateParams | uppercase) : (config?.title | transloco: config?.translateParams) }}\r\n          </h5>\r\n\r\n          <button\r\n            type=\"button\"\r\n            class=\"btn-close\"\r\n            data-bs-dismiss=\"modal\"\r\n            [attr.aria-label]=\"'NSA.ACTION.CLOSE' | transloco\"\r\n            (click)=\"onClose()\">\r\n          </button>\r\n        </div>\r\n\r\n        <div class=\"modal-body\">\r\n          <ng-content select=\"[modal-body]\"></ng-content>\r\n        </div>\r\n\r\n        <div class=\"modal-footer d-flex justify-content-center\">\r\n          <ng-content select=\"[modal-footer]\"></ng-content>\r\n        </div>\r\n      </div>\r\n    </ng-template>\r\n\r\n  </div>\r\n</div>\r\n"
    }]
  }], () => [{
    type: ModalViewerService
  }], null);
})();
class ModalModule {
  static {
    this.ɵfac = function ModalModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ModalModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: ModalModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [SharedModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ModalModule, [{
    type: NgModule,
    args: [{
      declarations: [ModalComponent],
      imports: [SharedModule],
      exports: [ModalComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AbstractModal, ModalComponent, ModalModule, ModalViewerService };
