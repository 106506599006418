export enum OrderTypeEnum {
  PRIVATE_EDUCATION = 'EDU-PRIV',
  FUNDED_TRAINING = 'EDU-FIN',
  INTERNAL_MANAGEMENT = 'MNG-INT',
  MANAGEMENT_CONSULTING = 'MNG-CON',
  ICT_OUTSOURCING = 'CON-SW',
  ICT_SOFTWARE_DEVELOPMENT = 'DEV-SW',
  FUNDED_PROJECT = 'DEV-FIN'
}

export enum OrderTypeIdEnum {
  PRIVATE_EDUCATION = 1,
  FUNDED_TRAINING = 2,
  INTERNAL_MANAGEMENT = 3,
  MANAGEMENT_CONSULTING = 4,
  ICT_OUTSOURCING = 5,
  ICT_SOFTWARE_DEVELOPMENT = 6,
  FUNDED_PROJECT = 7
}
