import { Pipe, PipeTransform } from '@angular/core';
import { translate } from '@jsverse/transloco';
import { MetadataStatusType } from '@shared/models/types/metadata-status.type';

@Pipe({
  name: 'metadataStatus',
  standalone: true
})
export class MetadataStatusPipe implements PipeTransform {

  transform(value: MetadataStatusType): string {
    return !!value ? translate(`DOCUMENTS.STATUS.${value.toString().toUpperCase()}`) : '';
  }

}
