<ng-container *transloco="let transloco">
  <nsa-modal>
    <ng-container modal-body>
      <div class="row">
        <div class="col-12 py-2">
          <nsa-datepicker
            nsaId="nsf-timesheet-search-date-id"
            nsaName="nsf-timesheet-search-date-name"
            [clearable]="false"
            [config]="dateConfig"
            [ngModel]="date"
            (valueChange)="onDateChange($event)">
          </nsa-datepicker>
        </div>
      </div>
    </ng-container>

    <ng-container modal-footer>
      <div class="d-grid gap-2 d-md-flex justify-content-md-center w-100">
        <nsa-button
          nsaId="nsf-cancel-button"
          type='button'
          icon="x-lg"
          color="dark"
          iconColor="danger"
          [fullWidth]="true"
          (click)="onAbort()">
          {{ transloco('ACTION.ABORT') }}
        </nsa-button>

        <nsa-button
          nsaId="nsf-confirm-button"
          type='submit'
          icon="check-lg"
          color="dark"
          iconColor="success"
          [fullWidth]="true"
          (click)="onConfirm()">
          {{ transloco('ACTION.CONFIRM') }}
        </nsa-button>
      </div>
    </ng-container>
  </nsa-modal>
</ng-container>
