import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AbstractModal, ModalViewerService } from '@nesea/ngx-ui-kit/modal';
import { IModalInput, IModalOutput, ITableColumn } from '@nesea/ngx-ui-kit/shared';
import { IOrder } from '@shared/models/interfaces/order.interface';

export interface ITimesheetActivationsModalInput extends IModalInput {
  orders: IOrder[];
}
export interface ITimesheetActivationsModalOutput extends IModalOutput {
}

@Component({
  selector: 'nsf-timesheet-activations-modal',
  templateUrl: './timesheet-activations-modal.component.html',
  styleUrl: './timesheet-activations-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimesheetActivationsModalComponent extends AbstractModal<ITimesheetActivationsModalInput, ITimesheetActivationsModalOutput> {

  columns: ITableColumn<IOrder>[] = [];
  items: IOrder[] = [];

  constructor(
    protected override modalViewerService: ModalViewerService
  ) {
    super(modalViewerService);
  }

  override onInit(): void {
    this.columns = [
      {
        id: 'name',
        label: 'LABEL.ORDER.TEXT',
        property: 'name'
      },
      {
        id: 'customer',
        label: 'LABEL.CUSTOMER.TEXT',
        property: 'clientName'
      },
      {
        id: 'type',
        label: 'LABEL.TYPE.TEXT',
        property: 'tipologiaCommessa',
        getValue: (item): string => item.tipologiaCommessa?.descrizione
      }
    ];

    this.items = [...this.data.orders].sort((a, b) => a.name.localeCompare(b.name));
  }
  override onDestroy(): void {}

}
