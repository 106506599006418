import { inject, Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { map, Observable, switchMap, tap } from 'rxjs';
import { IResponse, IResultMessage } from '@core/models/interfaces/response.interface';
import { IMetadata } from '@shared/models/interfaces/metadata.interface';
import { FileUtils } from '@shared/utils/file.utils';
import { FileSaverService } from '@nesea/ngx-ui-kit/file-saver';
import { ModalViewerService } from '@nesea/ngx-ui-kit/modal';
import { FilePreviewModalComponent, IFilePreviewModalInput, IFilePreviewModalOutput } from '@shared/modals/file-preview-modal/file-preview-modal.component';
import { MetadataType } from '@shared/models/types/metadata.type';
import { IDocumentRequestTimesheet } from '@shared/models/interfaces/timesheet.interface';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  private _httpClient: HttpClient = inject(HttpClient);
  private _fileSaverService: FileSaverService = inject(FileSaverService);
  private _modalViewerService: ModalViewerService = inject(ModalViewerService);

  getUserDocuments(userId: number): Observable<MetadataType[]> {
    return this._httpClient.get<IResponse<IMetadata[]>>(`/uploadedDocs/getAllMetadata/${userId}`).pipe(
      map(({ data }) => data),
      map(data => [...data].map<MetadataType>(item => ({
        ...item,
        status: !!item.dataValidazione ? 'approved' : (!!item.dataRifiuto ? 'rejected' : (!!item.dataEliminazione ? 'deleted' : 'to_approve'))
      })))
    );
  }

  getRequestDocuments(requestId: number): Observable<MetadataType[]> {
    return this._httpClient.get<IResponse<IMetadata[]>>(`/uploadedDocs/getMetadata/${requestId}`).pipe(
      map(({ data }) => data),
      map(data => [...data].map<MetadataType>(item => ({
        ...item,
        status: !!item.dataValidazione ? 'approved' : (!!item.dataRifiuto ? 'rejected' : (!!item.dataEliminazione ? 'deleted' : 'to_approve'))
      })))
    );
  }

  downloadDocument(documentId: number, documentName: string): Observable<Blob> {
    return this._httpClient.get<HttpResponse<Blob>>(`/uploadedDocs/get/${documentId}`, { responseType: 'blob' as 'json', observe: 'response' as 'body' }).pipe(
      map(({ body: blob, headers }) => ({
        blob,
        fileName: FileUtils.getFileName(headers.get('content-disposition'))
      })),
      tap(({ blob, fileName }) => this._fileSaverService.save(blob, fileName || documentName)),
      map(({ blob }) => blob)
    );
  }

  previewDocument(documentId: number): Observable<Blob> {
    return this._httpClient.get<HttpResponse<Blob>>(`/uploadedDocs/get/${documentId}`, { responseType: 'blob' as 'json', observe: 'response' as 'body' }).pipe(
      map(({ body: blob, headers }) => ({
        blob,
        fileName: FileUtils.getFileName(headers.get('content-disposition'))
      })),
      switchMap(({ blob, fileName }) => this._modalViewerService.open<IFilePreviewModalInput, IFilePreviewModalOutput>(FilePreviewModalComponent, {
        size: 'xl',
        data: { file: blob },
        title: 'DOCUMENTS.MODAL.PREVIEW.TITLE',
        translateParams: {
          fileName
        }
      }).pipe(
        map(() => blob)
      ))
    );
  }

  validateRequestDocument(documentId: number, note: string): Observable<IResultMessage> {
    const request = {
      idUploadedDoc: documentId,
      note
    };

    return this._httpClient.put<IResponse<IResultMessage>>(`/richiestaUtente/validateDoc`, request).pipe(
      map(({ data }) => data)
    );
  }

  rejectRequestDocument(documentId: number, note: string): Observable<IResultMessage> {
    const request = {
      idUploadedDoc: documentId,
      note
    };

    return this._httpClient.put<IResponse<IResultMessage>>(`/richiestaUtente/rejectDoc`, request).pipe(
      map(({ data }) => data)
    );
  }

  deleteRequestDocument(documentId: number, note: string): Observable<IResultMessage> {
    const request = {
      note
    };

    return this._httpClient.post<IResponse<IResultMessage>>(`/uploadedDocs/delete/${documentId}`, request).pipe(
      map(({ data }) => data)
    );
  }

  uploadRequestDocument(requestId: number, documentTypeId: number, file: File): Observable<boolean> {
    const request: FormData = new FormData();
    request.set('idRichiestaUtente', `${requestId}`);
    request.set('idCertificato', `${documentTypeId}`);
    request.set('file', file);
    // TODO: To check
    // request.set('note', '');

    return this._httpClient.post<IResponse<boolean>>(`/richiestaUtente/upload`, request).pipe(
      map(({ data }) => data)
    );
  }

  deleteTimesheetDocument(request: IDocumentRequestTimesheet): Observable<boolean> {
    return this._httpClient.put<IResponse<boolean>>(`/timesheet/allegato`, request).pipe(
      map(({ data }) => data)
    );
  }

  previewTimesheetDocument(request: IDocumentRequestTimesheet): Observable<Blob> {
    return this._httpClient.post<HttpResponse<Blob>>(`/timesheet/downloadAllegato`, request, { responseType: 'blob' as 'json', observe: 'response' as 'body' }).pipe(
      map(({ body: blob, headers }) => ({
        blob,
        fileName: FileUtils.getFileName(headers.get('content-disposition'))
      })),
      switchMap(({ blob, fileName }) => this._modalViewerService.open<IFilePreviewModalInput, IFilePreviewModalOutput>(FilePreviewModalComponent, {
        size: 'xl',
        data: { file: blob },
        title: 'DOCUMENTS.MODAL.PREVIEW.TITLE',
        translateParams: {
          fileName
        }
      }).pipe(
        map(() => blob)
      ))
    );
  }

  downloadTimesheetDocument(request: IDocumentRequestTimesheet): Observable<Blob> {
    return this._httpClient.post<HttpResponse<Blob>>(`/timesheet/downloadAllegato`, request, { responseType: 'blob' as 'json', observe: 'response' as 'body' }).pipe(
      map(({ body: blob, headers }) => ({
        blob,
        fileName: FileUtils.getFileName(headers.get('content-disposition'))
      })),
      tap(({ blob, fileName }) => this._fileSaverService.save(blob, fileName || request.nomeFile)),
      map(({ blob }) => blob)
    );
  }

}
