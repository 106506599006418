import { Pipe, PipeTransform } from '@angular/core';
import { ITypology } from '@shared/models/interfaces/typology.interface';

@Pipe({
  name: 'typologySort',
  standalone: true
})
export class TypologySortPipe implements PipeTransform {

  transform(value: ITypology[]): ITypology[] {
    return [...value].sort(({ name: previousName }, { name: currentName }) => previousName.localeCompare(currentName));
  }

}
