<ng-container *transloco="let transloco">
  <nsa-modal>
    <ng-container modal-body>
      <div class="row">
        <div class="col-12 p-2">
          <nsa-table
            nsaId="nsf-activations-table"
            type="collapsed"
            itemPropertyId="id"
            [columns]="columns"
            [items]="items">
          </nsa-table>
        </div>
      </div>
    </ng-container>
  </nsa-modal>
</ng-container>
