// TODO: Rename in eng
export enum RequestStatusEnum {
  FINALE = 'FNL',
  COMUNICATA = 'COM',
  ATTESA_APPROVAZIONE = 'ATTAPP',
  ATTESA_APPROVAZIONE_HR = 'ATTAPPHR',
  APPROVATA = 'APP',
  APPROVATA_HR = 'APPHR',
  RIFIUTATA = 'RIF',
  RIFIUTATA_HR = 'RIFHR',
  ANNULLATA = 'ANN',
  DOC_DA_VALIDARE = 'DOCDAVAL',
  DOC_VALIDATA = 'DOCVAL',
  DOC_DA_CARICARE = 'DOCDACAR'
}

export enum RequestStatusIdEnum {
  FINALE = 0,
  COMUNICATA = 1,
  ATTESA_APPROVAZIONE = 2,
  ATTESA_APPROVAZIONE_HR = 3,
  APPROVATA = 4,
  APPROVATA_HR = 5,
  RIFIUTATA = 6,
  RIFIUTATA_HR = 7,
  ANNULLATA = 8,
  DOC_DA_VALIDARE = 9,
  DOC_VALIDATA = 10,
  DOC_DA_CARICARE = 11
}
