import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IResponse } from '@core/models/interfaces/response.interface';
import { map, Observable } from 'rxjs';
import { IOrder } from '@shared/models/interfaces/order.interface';
import { FormConfigService } from '@core/services/form-config.service';
import { IActivationOrder } from '@shared/models/interfaces/activation-order.interface';
import { OrderTypeIdEnum } from '@shared/enums/order-type.enum';
import { ISearchResponse } from '@shared/models/interfaces/search-response.interface';
import { IActivationsMgmtSearchFilters } from '@shared/models/interfaces/activations/activations-mgmt-search-filters.interface';
import { IActivationMgmtSearchData } from '@shared/models/interfaces/activations/activation-search-data.interface';
import { IActivation } from '@shared/models/interfaces/activations/activation.interface';
import { IActivationCreateRequest } from '@features/activations-management/models/interfaces/activation-create-request.interface';
import { IActivationUpdateRequest } from '@features/activations-management/models/interfaces/activation-update-request.interface';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  private _httpClient: HttpClient = inject(HttpClient);
  private _formConfigService: FormConfigService = inject(FormConfigService);

  getActivations(filters: IActivationsMgmtSearchFilters): Observable<ISearchResponse<IActivationMgmtSearchData>> {
    return this._httpClient.post<IResponse<ISearchResponse<IActivationMgmtSearchData>>>(`/commesse/all`, { ...filters }).pipe(
      map(({ data }) => data)
    );
  }

  getActivation(activationId: number): Observable<IActivation> {
    return this._httpClient.get<IResponse<IActivation>>(`/commesse/${activationId}`).pipe(
      map(({ data }) => data)
    );
  }

  deleteActivation(activationId: number): Observable<boolean> {
    return this._httpClient.delete<IResponse<boolean>>(`/commesse/deleteCommessaUtente/${activationId}`).pipe(
      map(({ data }) => data)
    );
  }

  insertActivation(item: IActivationCreateRequest): Observable<boolean> {
    return this._httpClient.post<IResponse<boolean>>(`/commesse/saveCommessaUtente`, item).pipe(
      map(({ data }) => data)
    );
  }

  updateActivation(activationId: number, item: IActivationUpdateRequest): Observable<boolean> {
    return this._httpClient.put<IResponse<boolean>>(`/commesse/${activationId}`, item).pipe(
      map(({ data }) => data)
    );
  }

  getUserOrders(userId: number, startDate: number, endDate?: number): Observable<IOrder[]> {
    const body = {
      user: userId,
      startDate,
      endDate
    }
    return this._httpClient.post<IResponse<IOrder[]>>(`/commesse/byUserInRange`, body).pipe(
      map(({ data }) => data)
    );
  }

  getUserActivations(userId: number, startDate: number, endDate?: number): Observable<IOrder[]> {
    const body = {
      user: userId,
      startDate,
      endDate
    }
    return this._httpClient.post<IResponse<IOrder[]>>(`/commesse/activationInfo`, body).pipe(
      map(({ data }) => data)
    );
  }

  getNonAccountableUserOrders(userId: number, startDate: number, endDate?: number): Observable<IOrder[]> {
    const body = {
      user: userId,
      startDate,
      endDate
    }
    return this._httpClient.post<IResponse<IOrder[]>>(`/commesse/byUserInRangeNoConsuntivo`, body).pipe(
      map(({ data }) => data)
    );
  }

  searchOrders(term: string, orderType?: OrderTypeIdEnum, maxResults?: number): Observable<IActivationOrder[]> {
    const body = {
      searchText: term,
      maxResults: maxResults || this._formConfigService.config.autoCompleteMaxResults,
      tipologiaCommessaId: orderType || null
    }
    return this._httpClient.post<IResponse<IActivationOrder[]>>(`/commesse/autocomplete`, body).pipe(
      map(({ data }) => data)
    );
  }

}
