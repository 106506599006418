import { Pipe, PipeTransform } from '@angular/core';
import { translate } from '@jsverse/transloco';
import { SicknessTypeEnum } from '@shared/enums/sickness-type.enum';
import { EnumUtils } from '@shared/utils/enum.utils';

@Pipe({
  name: 'sicknessType',
  standalone: true
})
export class SicknessTypePipe implements PipeTransform {

  transform(value: SicknessTypeEnum): string {
    if(!value) return '';
    const key: string = EnumUtils.getKey(SicknessTypeEnum, value);
    return !!value ? translate(`LABEL.SICKNESS_TYPE.OPTIONS.${key}`) : '';
  }

}
