<ng-container *transloco="let transloco">
  <nsa-modal>
    <ng-container modal-body>
      <div class="nsf-file-preview-container">
        @if(fileSrc$ | ngrxPush; as fileSrc) {
          <iframe
            [src]="fileSrc | sanitizeUrl:'trustResourceUrl'">
          </iframe>
        }
      </div>
    </ng-container>
  </nsa-modal>
</ng-container>
