import { Pipe, PipeTransform } from '@angular/core';
import { translate } from '@jsverse/transloco';
import { EnumUtils } from '@shared/utils/enum.utils';
import { SkillCategoryEnum } from '@shared/enums/skill-category.enum';

@Pipe({
  name: 'skillCategory',
  standalone: true
})
export class SkillCategoryPipe implements PipeTransform {

  transform(value: SkillCategoryEnum): string {
    if(!value) return '';
    const key: string = EnumUtils.getKey(SkillCategoryEnum, value);
    return !!value ? translate(`MY_SKILLS.SKILL_CATEGORY.OPTIONS.${key}`) : '';
  }

}
