import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ITableColumn } from '@nesea/ngx-ui-kit/shared';
import { IOrder } from '@shared/models/interfaces/order.interface';

@Component({
  selector: 'nsf-timesheet-summary-activations',
  templateUrl: './timesheet-summary-activations.component.html',
  styleUrl: './timesheet-summary-activations.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimesheetSummaryActivationsComponent implements OnInit {

  @Input({ required: true }) orders: IOrder[];

  columns: ITableColumn<IOrder>[] = [];
  items: IOrder[] = [];

  ngOnInit(): void {
    this.columns = [
      {
        id: 'name',
        label: 'LABEL.ORDER.TEXT',
        property: 'name'
      },
      {
        id: 'customer',
        label: 'LABEL.CUSTOMER.TEXT',
        property: 'clientName'
      },
      {
        id: 'type',
        label: 'LABEL.TYPE.TEXT',
        property: 'tipologiaCommessa',
        getValue: (item): string => item.tipologiaCommessa?.descrizione
      }
    ];

    this.items = [...this.orders].sort((a, b) => a.name.localeCompare(b.name));
  }

}
