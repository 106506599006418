<ng-container *transloco="let transloco">
  <nsa-modal>
    <ng-container modal-sidebar>
      <ul
        class="nsa-nav-sidebar-tabs nav nav-fill flex-column gap-1"
        role="tablist">
        @for(action of actions; track action.id) {
          <li
            *transloco="let transloco"
            class="nav-item"
            role="presentation">
            <button
              [attr.id]="action.id + '-link'"
              [attr.aria-selected]="isActive(action.id)"
              [attr.title]="transloco(action.label)"
              [attr.aria-controls]="activePanelId"
              tabindex="0"
              class="nav-link text-start"
              [ngClass]="{ 'active': isActive(action.id) }"
              type="button"
              role="tab"
              (click)="onActionClick(action.id, action.extra.panelId)">
              <div class="text-truncate">
                <i class="bi bi-{{action.icon}} me-2"></i>
                {{ transloco(action.label) }}
              </div>
            </button>
          </li>
        }
      </ul>
    </ng-container>

    <ng-container modal-body>
      <div class="row flex-column">
        <div class="col pb-2 text-end">
          <span class="fw-bold">{{ transloco('TIMESHEET.REMAINING_ORDERS') }}&#58; {{ remainingParsedHours() }}</span>
        </div>

        <!-- TODO: Move in page -->
        <div class="col pb-2 text-end">
          <span class="fw-bold">{{ transloco('TIMESHEET.MEAL_VOUCHERS') }}&#58; {{ data.mealVouchers }}</span>
        </div>
      </div>

      <div class="row"
           [attr.id]="activePanelId">
        <div class="col-12 py-2">
          @switch (activePanelId) {
            @case ('activations-panel') {
              <nsf-timesheet-summary-activations
                [orders]="data.orders">
              </nsf-timesheet-summary-activations>
            }
            @case ('orders-panel') {
              <nsf-timesheet-summary-orders
                [workingDays]="data.workingDays">
              </nsf-timesheet-summary-orders>
            }
            @case ('overtimes-panel') {
              <nsf-timesheet-summary-overtimes
                [overtimeDays]="data.overtimeDays">
              </nsf-timesheet-summary-overtimes>
            }
            @case ('absences-panel') {
              <nsf-timesheet-summary-absences
                [absences]="data.absences">
              </nsf-timesheet-summary-absences>
            }
            @case ('documents-panel') {
              <nsf-timesheet-summary-documents
                [timesheetId]="data.timesheetId"
                [currentMonth]="data.currentMonth"
                [currentYear]="data.currentYear"
                [documents]="data.documents">
              </nsf-timesheet-summary-documents>
            }
            @case ('history-panel') {
              <nsf-timesheet-summary-history
                [history]="data.history">
              </nsf-timesheet-summary-history>
            }
          }
        </div>
      </div>
    </ng-container>
  </nsa-modal>
</ng-container>
