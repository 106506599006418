import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizeUrl',
  standalone: true
})
export class SanitizeUrlPipe implements PipeTransform {

  constructor (
    private sanitize: DomSanitizer
  ) {}

  transform(value: string, bypass: 'trustUrl' | 'trustResourceUrl' = 'trustUrl'): SafeUrl {
    if(!value) return undefined;
    if(bypass === 'trustUrl') return this.sanitize.bypassSecurityTrustUrl(value);
    else return this.sanitize.bypassSecurityTrustResourceUrl(value);
  }
}
