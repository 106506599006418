export enum SkillCategoryEnum {
  SCOPE = 'AMBITO',
  TECHNICAL = 'TECNICA',
  SOFT = 'SOFT'
}

export enum SkillCategoryIdEnum {
  SCOPE = 1,
  TECHNICAL = 2,
  SOFT = 3
}
