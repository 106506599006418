import { Pipe, PipeTransform } from '@angular/core';
import { ITypologyIT } from '@shared/models/interfaces/typology.interface';

@Pipe({
  name: 'typologyITSort',
  standalone: true
})
export class TypologySortITPipe implements PipeTransform {

  transform(value: ITypologyIT[]): ITypologyIT[] {
    return [...value].sort(({ nome: previousName }, { nome: currentName }) => previousName.localeCompare(currentName));
  }

}
